import jwt from 'jsonwebtoken'

export const TOKEN_KEY = '@vibe-portal-Token'

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (token) => localStorage.setItem(TOKEN_KEY, token)

export const logout = () => localStorage.removeItem(TOKEN_KEY)

export const isAuthenticated = () => {
  const token = getToken()

  if (!token) return false

  // seconds since Epoch
  const { exp } = jwt.decode(token, { json: true })

  const expired = Date.now() >= exp * 1000

  if (expired) {
    logout()
    return false
  }

  return true
}
