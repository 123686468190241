import React, { useState } from 'react'
import { Form, Button, FormGroup, Input, Col, Label, Alert } from 'reactstrap'
import { Link } from 'react-router-dom'
import AuthLayout from './AuthLayout'
import api from '@/services/api'

const resetBaseUrl = process.env.REACT_APP_URL + '/reset-password'

function ForgotPasswordPage() {
  console.log('resetBaseUrl', resetBaseUrl)
  const [formValues, setFormValues] = useState({
    email: '',
    resetBaseUrl,
  })

  const [success, setSuccess] = useState(false)

  const handleChange = (e) =>
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })

  const handleSubmit = (e) => {
    e.preventDefault()
    api.auth.forgotPassword(formValues).then(() => setSuccess(true))
  }

  return (
    <AuthLayout>
      <Col sm="12" md="6" lg="4">
        <h1 className="login-heading">RECUPERAÇÃO DE SENHA</h1>
        {success ? (
          <Alert color="info">
            Um link de recuperação de senha foi enviado para o endereço&nbsp;
            <strong>{formValues.email}</strong>. Verifique sua caixa de entrada.
          </Alert>
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormGroup name="email">
              <Label htmlFor="email">Insira seu e-mail:</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="nome@exemplo.com"
                required
                value={formValues.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Button size="lg" type="submit" color="primary" block>
                Enviar
              </Button>
            </FormGroup>
          </Form>
        )}
        <div className="my-3">
          <Link to="/login" className="text-reset">
            Já sei minha senha
          </Link>
        </div>
      </Col>
    </AuthLayout>
  )
}

export default ForgotPasswordPage
