import React from 'react'

function SupportPage() {
  return (
    <div>
      <h1>Abertura de chamado</h1>
      <p>
        Para solicitar atendimento, envie um e-mail para
        <strong> suporte@vibeclub.com.br </strong>
        com os seguintes dados:
      </p>

      <h4>Descrição da ocorrência</h4>

      <p>
        Descreva com clareza o que aconteceu com o dispositivo, detalhando a
        data e hora do ocorrido.
      </p>

      <h4>Documentos</h4>

      <p>
        Os documentos a seguir serão solicitados para realizar o atendimento.
        Envie-os em anexo com o e-mail:
      </p>

      <ul>
        <li>Boletim de ocorrência</li>
        <li>Nota fiscal do dispositivo</li>
        <li>Documento de identificação do proprietário</li>
        <li>Possíveis evidências/fotos que comprovem o ocorrido</li>
      </ul>
    </div>
  )
}

export default SupportPage
