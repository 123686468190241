import React from 'react'
import Icon from '@/components/Icon'

function ToastError({ text }) {
  return (
    <div>
      <Icon name="exclamation-triangle" />
      &nbsp;{text}
    </div>
  )
}

export default ToastError
