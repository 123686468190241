import React, { useState } from 'react'
import { Col, Row, Button, FormGroup } from 'reactstrap'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import Input from '@/components/Input'
import api from '@/services/api'
import { login } from '@/services/auth'
import { fireErrorToast, fireSuccessToast } from '@/helpers/notifications'
import validators from '@/validators'

const validationSchema = yup.object().shape({
  currentPassword: validators.requiredString,
  newPassword: validators.validPassword,
})

function ProfilePage() {
  const [loading, setLoading] = useState(false)

  const handleSubmit = (payload) => {
    if (!loading) {
      setLoading(true)

      api.auth
        .changePassword(payload)
        .then(({ token }) => {
          login(token)
          fireSuccessToast('Senha atualizada com sucesso!')
        })
        .catch(() =>
          fireErrorToast(
            'Erro ao tentar atualizar a senha. Verifique os dados no formulário.',
          ),
        )
        .finally(() => setLoading(false))
    }
  }
  return (
    <Row>
      <Col xs="12" className="mb-5">
        <h1>Meu perfil</h1>
        <p className="text-muted">
          Atualize e mantenha em dia as informações do seu perfil
        </p>
      </Col>
      <Col xs="12" className="mb-5">
        <Row>
          <Col xs="12" md="6" lg="4">
            <h3>Aterar senha</h3>
            <Formik
              initialValues={{
                currentPassword: '',
                newPassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange }) => (
                <Form>
                  <Input
                    label="Senha atual:"
                    name="currentPassword"
                    type="password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                    value={values.currentPassword}
                    onChange={handleChange}
                    error={errors.currentPassword}
                  />
                  <Input
                    label="Nova senha:"
                    name="newPassword"
                    type="password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                    value={values.newPassword}
                    onChange={handleChange}
                    error={errors.newPassword}
                  />
                  <FormGroup>
                    <Button type="submit" color="primary" disabled={loading}>
                      Atualizar senha
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ProfilePage
