import React, { useState } from 'react'
import { Container, Nav, Navbar, NavbarText, NavItem } from 'reactstrap'
import cn from 'classnames'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { logout } from '@/services/auth'
import logo from '@/assets/images/logo-color.png'
// import home from '@/assets/images/icons/home.svg'
import file from '@/assets/images/icons/file.svg'
import profile from '@/assets/images/icons/user.svg'
import help from '@/assets/images/icons/help-chat.svg'

function SidebarToggler({ setActive }) {
  return (
    <span
      className="sidebar-toggler"
      onClick={() => setActive((isActive) => !isActive)}
    >
      &bull;&bull;&bull;
    </span>
  )
}

function Layout({ children }) {
  const history = useHistory()
  const [active, setActive] = useState(false)

  const handleSignout = () => {
    logout()
    history.push('/')
  }

  return (
    <div className="layout">
      <div
        className={cn('sidebar', {
          active,
        })}
      >
        <div className="sidebar-top">
          <div>
            <strong>Menu</strong>
          </div>
          <div className="d-flex align-items-center">
            <SidebarToggler setActive={setActive} />
          </div>
        </div>
        <div>
          {/* <NavLink
            exact
            to="/club"
            className="sidebar-link"
            activeClassName="active"
          >
            <img src={home} alt="" height="20" className="mr-2" />
            Home
          </NavLink> */}
          <NavLink
            exact
            to="/club/subscriptions"
            className="sidebar-link"
            activeClassName="active"
          >
            <img src={file} alt="" height="20" className="mr-2" />
            Meus planos
          </NavLink>
          <NavLink
            exact
            to="/club/support"
            className="sidebar-link"
            activeClassName="active"
          >
            <img src={help} alt="" height="20" className="mr-2" />
            Atendimento
          </NavLink>
          <NavLink
            exact
            to="/club/profile"
            className="sidebar-link"
            activeClassName="active"
          >
            <img src={profile} alt="" height="20" className="mr-2" />
            Meu perfil
          </NavLink>
        </div>
      </div>
      <div className="content-wrapper">
        <Navbar>
          <Nav navbar>
            <NavItem>
              <NavbarText>
                <SidebarToggler setActive={setActive} />
              </NavbarText>
            </NavItem>
            <NavItem>
              <Link to="/" className="navbar-link">
                <img src={logo} height="40" alt="" />
              </Link>
            </NavItem>
          </Nav>
          <Nav navbar className="ml-auto pointer">
            <NavItem>
              <NavbarText onClick={handleSignout}>Sair</NavbarText>
            </NavItem>
          </Nav>
        </Navbar>
        <div className="content">
          <Container fluid>{children}</Container>
        </div>
      </div>
    </div>
  )
}

export default Layout
