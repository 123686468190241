import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { isAuthenticated } from '@/services/auth'
// import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/auth/LoginPage'
import ForgotPasswordPage from '@/pages/auth/ForgotPasswordPage'
import Layout from '@/components/Layout'
import NotFound from '@/pages/NotFound'
import SignupPage from '@/pages/auth/SignupPage'
import SubscribePage from '@/pages/SubscribePage'
import SubscriptionsPage from './pages/SubscriptionsPage'
import SupportPage from './pages/SupportPage'
import ProfilePage from './pages/ProfilePage'
import ResetPasswordPage from './pages/auth/ResetPasswordPage'

function PrivateRoute({ children, ...rest }) {
  const render = ({ location }) =>
    isAuthenticated() ? (
      children
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: location } }} />
    )

  return <Route {...rest} render={render} />
}

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/club" />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/registrar">
            <SignupPage />
          </Route>
          <Route exact path="/esqueci-minha-senha">
            <ForgotPasswordPage />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordPage />
          </Route>
          <PrivateRoute path="/club">
            <Layout>
              <Switch>
                <Route exact path="/club">
                  {/* <HomePage /> */}
                  <Redirect to="/club/subscriptions/" />
                </Route>
                <Route exact path="/club/subscribe/:planId">
                  <SubscribePage />
                </Route>
                <Route exact path="/club/subscriptions">
                  <SubscriptionsPage />
                </Route>
                <Route exact path="/club/support">
                  <SupportPage />
                </Route>
                <Route exact path="/club/profile">
                  <ProfilePage />
                </Route>
                <Route path="/club/*">
                  <NotFound />
                </Route>
              </Switch>
            </Layout>
          </PrivateRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>
  )
}

export default App
