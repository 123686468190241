import PaymentForm from '@/components/PaymentForm'
import { formatMoney } from '@/helpers/formatHelpers'
import api from '@/services/api'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'
import NotFound from './NotFound'

function WarrantyListItem({ title, text }) {
  return (
    <Media tag="li" className="mb-3">
      <Media left className="mr-2">
        <img src="/images/security.svg" height="24" alt="" />
      </Media>
      <Media body>
        <Media className="text-left">{title}</Media>
        <small className="text-muted text-left">{text}</small>
      </Media>
    </Media>
  )
}

function SubscribePage() {
  const [plan, setPlan] = useState(null)
  const [loading, setLoading] = useState(true)
  const { planId } = useParams()
  const history = useHistory()

  useEffect(() => {
    api.plans
      .get(planId)
      .then(setPlan)
      .finally(() => setLoading(false))
  }, [planId])

  const handleSubmit = () => {
    // TODO: make payment
    api.plans.subscribe(planId).then(() => history.push('/club/subscriptions'))
  }

  if (loading) {
    return <div>Carregando...</div>
  }

  if (!plan) {
    return <NotFound />
  }

  return (
    <Row>
      <Col xs="12" className="mb-5">
        <h1>Contratar plano</h1>
      </Col>
      <Col lg="4">
        <div className="text-center">
          <h4 className="text-muted mb-4">Resumo</h4>
          <div className="mb-4">
            <div className="mb-4">
              {plan.device.image && (
                <img
                  src={plan.device.image.url}
                  alt={plan.device.name}
                  height="120"
                />
              )}
            </div>
            <div>
              <h5 className="mb-3">{plan.name}</h5>
            </div>
          </div>
        </div>

        <Row className="justify-content-center">
          <Media list className="mb-4 pl-0">
            <WarrantyListItem
              title="Roubo e furto qualificado"
              text="Consulte o regulamento"
            />
            <WarrantyListItem title="Acidentes físicos" />
            <WarrantyListItem title="Água e curto circuito" />
            <WarrantyListItem
              title="Problemas técnicos"
              text="Assistência técnica e suporte 24H"
            />
          </Media>
        </Row>

        <div className="text-center mb-4">
          <h5 className="text-muted mb-3">Valor:</h5>
          <h4 className="mb-2">
            <strong>{formatMoney(plan.priceMonth)}/mês</strong>
          </h4>
          <div>
            <span className="text-muted mb-3">Franquia: </span>
            {formatMoney(plan.franchisePrice)}
          </div>
        </div>
      </Col>
      <Col lg="8" xl="6">
        <Card>
          <CardBody>
            <h4 className="text-muted mb-4">Pagamento</h4>
            <PaymentForm onSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default SubscribePage
