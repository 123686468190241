import moment from 'moment'

export const formatMoney = (value) => {
  const num = Number(value) || 0

  return num.toLocaleString('pt-br', {
    currency: 'BRL',
    style: 'currency',
  })
}

export const formatDate = (value, format) => {
  const date = moment(value)
  if (date.isValid()) {
    return date.format(format || 'DD/MM/YYYY')
  }
  return ''
}

export const formatTime = (value, format) =>
  formatDate(value, format || 'HH:mm')

export const formatDateTime = (value, format) =>
  formatDate(value, format || 'DD/MM/YYYY HH:mm')

export const formatTimeStamp = (value, format) =>
  formatDate(value, format || 'DD/MM/YYYY HH:mm:ss')

export const formatNumber = (value) =>
  (Number(value) || 0).toLocaleString('pt-br')
