import React from 'react'
import { toast } from 'react-toastify'
import Icon from '@/components/Icon'
import ToastError from '@/components/ToastError'
import ToastSuccess from '@/components/ToastSuccess'

export const getLoadingToast = (render) =>
  toast(
    <div>
      <Icon name="cog" spin />
      &nbsp;{render || 'Aguarde...'}
    </div>,
    {
      autoClose: false,
    },
  )

export const updateToast = (ref, type, render, options) =>
  toast.update(ref.current, {
    render,
    type,
    autoClose: 5000,
    ...options,
  })

export const updateToastSuccess = (ref, text) =>
  updateToast(ref, 'success', <ToastSuccess text={text} />)

export const updateToastError = (ref, text) =>
  updateToast(ref, 'error', <ToastError text={text} />)

export const fireErrorToast = (text) =>
  toast(<ToastError text={text} />, { type: 'error' })

export const fireSuccessToast = (text) =>
  toast(<ToastSuccess text={text} />, { type: 'success' })
