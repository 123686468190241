import React, { useState } from 'react'
import qs from 'query-string'
import { Form, Button, FormGroup, Input, Col, Label, Alert } from 'reactstrap'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import { isAuthenticated, login } from '@/services/auth'
import { auth } from '@/services/api'
import AuthLayout from './AuthLayout'

function LoginPage() {
  const location = useLocation()
  const history = useHistory()

  const { returnUrl } = qs.parse(location.search)

  const { from } = returnUrl
    ? { from: { pathname: decodeURIComponent(returnUrl) } }
    : location.state || { from: { pathname: '/' } }

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  })

  const [error, setError] = useState(null)

  const handleChange = (e) =>
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    auth
      .login(formValues)
      .then((data) => {
        login(data.token)

        history.replace(from)
      })
      .catch(() => {
        setError('E-mail ou senha incorretos.')
      })
  }

  return isAuthenticated() ? (
    <Redirect to={from} />
  ) : (
    <AuthLayout>
      <Col sm="12" md="6" lg="4">
        <h1 className="login-heading">FAZER LOGIN NO SISTEMA</h1>
        <Form onSubmit={handleSubmit}>
          <FormGroup name="email">
            <Label htmlFor="email">E-mail:</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="nome@exemplo.com"
              required
              value={formValues.email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup name="password" className="mb-4">
            <Label htmlFor="password">Senha:</Label>
            <Input
              required
              type="password"
              name="password"
              id="password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              value={formValues.password}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Button size="lg" type="submit" color="primary" block>
              Entrar
            </Button>
          </FormGroup>
          {error && <Alert color="danger">{error}</Alert>}
          <div className="my-3">
            <Link
              to={{
                ...location,
                pathname: '/registrar',
              }}
              className="text-reset"
            >
              Ainda não sou cadastrado
            </Link>
          </div>
          <div className="my-3">
            <Link to="/esqueci-minha-senha" className="text-reset">
              Esqueci minha senha
            </Link>
          </div>
        </Form>
      </Col>
    </AuthLayout>
  )
}

export default LoginPage
