import React from 'react'
import Icon from './Icon'

function ToastSuccess({ text }) {
  return (
    <div>
      <Icon name="check" />
      &nbsp;{text}
    </div>
  )
}

export default ToastSuccess
