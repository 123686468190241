import React, { useState } from 'react'
import qs from 'query-string'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'

import { Form, Button, FormGroup, Col } from 'reactstrap'

import AuthLayout from './AuthLayout'
import { auth } from '@/services/api'
import { isAuthenticated, login } from '@/services/auth'
import Input from '@/components/Input'

function SignupPage() {
  const location = useLocation()
  const history = useHistory()

  const { returnUrl } = qs.parse(location.search)

  const [formValues, setFormValues] = useState({
    fullName: '',
    cpf: '',
    birthday: '',
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) =>
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    // TODO: redirect to email confirmation

    setErrors({})

    auth
      .register(formValues)
      .then((data) => {
        login(data.token)
        history.replace({
          pathname: returnUrl ? decodeURIComponent(returnUrl) : '/',
        })
      })
      .catch(({ response }) => {
        if (response) {
          setErrors(response.data.errors || {})
        }
      })
  }

  return isAuthenticated() && returnUrl ? (
    <Redirect to={decodeURIComponent(returnUrl)} />
  ) : (
    <AuthLayout>
      <Col sm="12" md="6" lg="4">
        <h1 className="login-heading mb-4">CRIAR UMA NOVA CONTA</h1>

        <div className="mb-5">
          <Link
            to={{
              ...location,
              pathname: '/login',
            }}
          >
            Já sou cadastrado (entrar)
          </Link>
        </div>

        <Form onSubmit={handleSubmit} noValidate>
          <Input
            name="fullName"
            label="Seu nome:"
            placeholder="Nome Completo"
            value={formValues.fullName}
            onChange={handleChange}
            error={errors.fullName}
          />
          <Input
            name="cpf"
            label="Seu CPF:"
            placeholder="Seu CPF"
            value={formValues.cpf}
            onChange={handleChange}
            error={errors.cpf}
          />
          <Input
            type="date"
            name="birthday"
            label="Data de nascimento:"
            placeholder="dd/mm/aaaa"
            value={formValues.birthday}
            onChange={handleChange}
            error={errors.birthday}
          />
          <Input
            type="email"
            name="email"
            label="E-mail:"
            placeholder="nome@exemplo.com"
            value={formValues.email}
            onChange={handleChange}
            error={errors.email}
          />
          <Input
            type="password"
            name="password"
            label="Crie uma senha:"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            value={formValues.password}
            onChange={handleChange}
            error={errors.password}
          />
          <FormGroup>
            <Button size="lg" type="submit" color="primary" block>
              Cadastrar
            </Button>
          </FormGroup>
        </Form>
      </Col>
    </AuthLayout>
  )
}

export default SignupPage
