import apiRequest from './apiRequest'
import axios from 'axios'

export const auth = {
  login: (credentials) =>
    apiRequest.post('/auth/login', credentials).then(({ data }) => data),
  register: (payload) =>
    apiRequest.post('/auth/register', payload).then(({ data }) => data),
  forgotPassword: (payload) => apiRequest.post('/auth/forgotpassword', payload),
  resetPassword: (payload) =>
    apiRequest.post('/auth/resetpassword', payload).then(({ data }) => data),
  changePassword: (payload) =>
    apiRequest.post('/auth/changepassword', payload).then(({ data }) => data),
}

export const address = {
  search: (cep) =>
    axios
      .get(`http://cep.la/${cep}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(({ data }) => {
        if (data && data.cep) {
          return {
            cep: data.cep,
            state: data.uf,
            city: data.cidade,
            neighborhood: data.bairro,
            street: data.logradouro,
          }
        } else {
          return {}
        }
      })
      .catch(() => {
        console.warn('CEP seacrh API not responding')
      }),
}

export const devices = {
  list: () => apiRequest.get('/devices'),
  get: (id) => apiRequest.get(`/devices/${id}`),
}

export const plans = {
  list: () => apiRequest.get('/plans'),
  get: (id) => apiRequest.get(`/plans/${id}`).then(({ data }) => data),
  subscribe: (id) => apiRequest.post(`/plans/${id}/subscribe`),
  getSubscriptions: () =>
    apiRequest.get(`/plans/mysubscriptions`).then(({ data }) => data),
}

export default {
  auth,
  address,
  devices,
  plans,
}
