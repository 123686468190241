import { formatDateTime } from '@/helpers/formatHelpers'
import api from '@/services/api'
import React, { useEffect, useState } from 'react'
import { Badge, Card, CardBody, Col, Media, Row } from 'reactstrap'

function SubscriptionsPage() {
  const [loading, setLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    api.plans
      .getSubscriptions()
      .then(setSubscriptions)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <div className="text-muted">Carregando...</div>
  }

  if (!subscriptions.length) {
    return (
      <div className="text-muted">
        <div className="mb-3">
          Você ainda não contratou nenhum plano. Entre no site e faça já uma
          simulação!
        </div>
        <a href="https://vibeclub.app" className="btn btn-primary">
          Vamos lá!
        </a>
      </div>
    )
  }

  return (
    <Row>
      <Col xs="12" className="mb-5">
        <h1>Meus planos</h1>
        <p className="text-muted">Veja aqui os planos contratados por você</p>
        <Row>
          {subscriptions.map((sub) => {
            const { plan } = sub
            const { device } = plan
            const processNumber = sub.processId.padStart(6, 0)

            return (
              <Col key={sub.id} md="6" className="mb-3">
                <Card className="mb-4">
                  <CardBody>
                    <h5>
                      <strong>
                        {processNumber} - {sub.plan.name}
                      </strong>
                    </h5>
                    <Media>
                      <Media style={{ width: 120 }}>
                        {device.image ? (
                          <img
                            src={device.image.url}
                            alt={device.name}
                            width="120"
                          />
                        ) : (
                          <span className="text-muted">Sem foto</span>
                        )}
                      </Media>
                      <Row style={{ flex: 1 }}>
                        <Col xs="6">Estado:</Col>
                        <Col xs="6" className="text-muted">
                          <Badge color="success">{sub.status}</Badge>
                        </Col>
                        <Col xs="6">Núm. Processo:</Col>
                        <Col xs="6" className="text-muted">
                          {processNumber}
                        </Col>
                        <Col xs="6">Dispositivo:</Col>
                        <Col xs="6" className="text-muted">
                          {device.name}
                        </Col>
                        <Col xs="6">IMEI:</Col>
                        <Col xs="6" className="text-muted">
                          {sub.imei || 'N/D'}
                        </Col>
                        <Col xs="6">Número de série:</Col>
                        <Col xs="6" className="text-muted">
                          {sub.serialNumber || 'N/D'}
                        </Col>
                        <Col xs="6">Data contrato:</Col>
                        <Col xs="6" className="text-muted">
                          {formatDateTime(sub.startDate)}
                        </Col>
                      </Row>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

export default SubscriptionsPage
