import React from 'react'

function Icon({
  name,
  size,
  spin,
  pulse,
  color,
  onClick,
  className,
  style,
  ...rest
}) {
  const sizeClass = size ? `fa-${size}x` : ''
  const spinClass = spin ? 'fa-spin fa-fw' : pulse ? 'fa-pulse fa-fw' : ''
  const colorClass = color ? `text-${color}` : ''

  return (
    <i
      className={[
        'fa',
        `fa-${name}`,
        sizeClass,
        spinClass,
        colorClass,
        className,
      ].join(' ')}
      style={{ cursor: onClick ? 'pointer' : 'inherit', ...style }}
      onClick={onClick}
      {...rest}
    />
  )
}

export default Icon
