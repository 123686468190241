import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Alert, Button, Col, FormGroup } from 'reactstrap'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import qs from 'query-string'
import validators from '@/validators'
import api from '@/services/api'
import AuthLayout from './AuthLayout'
import Input from '@/components/Input'

function ResetPasswordPage() {
  const location = useLocation()
  const { token, email } = qs.parse(location.search)
  const [success, setSucess] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = ({ password }) => {
    setSucess(false)
    setError(null)

    api.auth
      .resetPassword({ email, token, password })
      .then((result) => {
        if (result.succeeded) {
          setSucess(true)
        } else {
          if (result && result.errors && /password/gi.test(result.errors[0])) {
            setError(
              'Sua senha deve conter no mínimo 8 caracteres, sendo pelo menos: ' +
                '1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial (ex.: ! @ # $ %).',
            )
          } else {
            setError(
              'O link de alteração de senha atual é inválido ou expirou.',
            )
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 404) {
          setError('O link de alteração de senha atual é inválido ou expirou.')
        }
      })
  }

  return (
    <AuthLayout>
      <Col sm="12" md="6" lg="4">
        <h1 className="login-heading">ALTERAÇÃO DE SENHA</h1>

        {success ? (
          <Alert color="success">
            Sua senha foi alterada.&nbsp;
            <Link to="/login" className="strong text-reset font-weight-bold">
              Clique aqui
            </Link>
            &nbsp;para entrar no sistema
          </Alert>
        ) : (
          <div>
            <p>
              Olá, <strong>{email}</strong>.
              <br />
              Preencha o formulário abaixo para alterar sua senha.
            </p>

            <Formik
              onSubmit={handleSubmit}
              initialValues={{
                password: '',
              }}
              validationSchema={yup.object().shape({
                password: validators.validPassword,
              })}
            >
              {({ values, errors, handleChange }) => (
                <Form>
                  <FormGroup>
                    <Input
                      label="Insira sua nova senha:"
                      type="password"
                      name="password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
                      onChange={handleChange}
                      value={values.password}
                      error={errors.password}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button type="submit" color="primary" block>
                      Enviar
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
            {error && <Alert color="danger">{error}</Alert>}
          </div>
        )}
        <div className="my-3">
          <Link to="/login">Já sei minha senha</Link>
        </div>
        <div className="my-3">
          <Link to="/esqueci-minha-senha">
            Solicitar outro link (esqueci minha senha)
          </Link>
        </div>
      </Col>
    </AuthLayout>
  )
}

export default ResetPasswordPage
