import React from 'react'
import {
  FormFeedback,
  FormGroup,
  Input as ReactstrapInput,
  Label,
} from 'reactstrap'

/**
 *
 * @param {{error: string, label: string} & import('reactstrap/lib/Input').InputProps} props
 */
function Input({ name, error, label, ...rest }) {
  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <ReactstrapInput name={name} id={name} invalid={!!error} {...rest} />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}

export default Input
