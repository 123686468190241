import React from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { Button, Col, FormGroup, FormFeedback, Input, Row } from 'reactstrap'
import { stripNonDigits } from '@/helpers/stringHelpers'

const validationSchema = yup.object({
  cardNumber: yup
    .string()
    .transform(stripNonDigits)
    .required('Insira o número do cartão.')
    .length(16, 'Número de cartão inválido.'),

  code: yup
    .string()
    .transform(stripNonDigits)
    .required('Insira o código de segurança.')
    .length(3, 'Código de segurança inválido'),

  cpf: yup
    .string()
    .transform(stripNonDigits)
    .required('Insira o CPF.')
    .length(11, 'CPF inválido.'),

  fullName: yup
    .string()
    .trim()
    .matches(/\s/g, 'Verifique o nome no cartão.')
    .required('Insira o nome completo do titular.')
    .min(3, 'Insira o nome completo do titular.'),

  // if month is less then current, year must be at least next year
  year: yup.number().when('month', {
    is: (m) => m < new Date().getMonth() + 1,
    then: yup.number().moreThan(new Date().getFullYear()),
    otherwise: yup.number().min(new Date().getFullYear()),
  }),

  month: yup.number().min(1).max(12),
})

function PaymentForm({ onSubmit }) {
  return (
    <Formik
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={validationSchema}
      initialValues={{
        cardNumber: '',
        code: '',
        cpf: '',
        fullName: '',
        year: new Date().getFullYear().toString(),
        month: new Date().getMonth() + 1,
      }}
    >
      {({ values, errors }) => {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()

        const months =
          values.year === currentYear
            ? Array(12 - currentMonth)
                .fill(0)
                .map((_, i) => currentMonth + i + 1)
            : Array(12)
                .fill(0)
                .map((_, i) => i + 1)

        return (
          <Form>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label htmlFor="cardNumber">Número do cartão:</label>
                  <Field
                    name="cardNumber"
                    placeholder="xxxx.xxxx.xxxx.xxxx"
                    as={Input}
                    invalid={!!errors.cardNumber}
                  />
                  <FormFeedback>{errors.cardNumber}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <label htmlFor="cpf">CPF do titular:</label>
                  <Field
                    name="cpf"
                    type="tel"
                    placeholder="000.000.000-00"
                    as={Input}
                    invalid={!!errors.cpf}
                  />
                  <FormFeedback>{errors.cpf}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <label htmlFor="fullName">Nome completo do titular:</label>
                  <Field
                    name="fullName"
                    placeholder="Nome escrito no cartão"
                    as={Input}
                    invalid={!!errors.fullName}
                  />
                  <FormFeedback>{errors.fullName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <label htmlFor="month">Vencimento - Mês:</label>
                  <Field
                    name="month"
                    type="select"
                    placeholder="01"
                    as={Input}
                    invalid={!!errors.month}
                  >
                    {months.map((month) => (
                      <option key={`month-${month}`} value={month}>
                        {month}
                      </option>
                    ))}
                  </Field>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <label htmlFor="year">Ano:</label>
                  <Field
                    name="year"
                    type="select"
                    placeholder={new Date().getFullYear()}
                    as={Input}
                    invalid={!!errors.year}
                  >
                    {Array(12)
                      .fill(0)
                      .map((e, i) => (
                        <option
                          key={`year-${i}`}
                          value={new Date().getFullYear() + i}
                        >
                          {new Date().getFullYear() + i}
                        </option>
                      ))}
                  </Field>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <label htmlFor="code">Código de segurança:</label>
                  <Field
                    name="code"
                    placeholder="000"
                    as={Input}
                    invalid={!!errors.code}
                  />
                  <FormFeedback>{errors.code}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Button color="primary" size="lg" block>
                    Confirmar pedido
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PaymentForm
