import React from 'react'
import './auth.css'
import { Container, Row } from 'reactstrap'
import logo from '@/assets/images/logo-color.png'

function AuthLayout({ children }) {
  return (
    <React.Fragment>
      <main className="text-center">
        <Container className="py-5">
          <div className="mb-5">
            <img
              height="65"
              src={logo}
              alt="VIBE Club"
              title="Página inicial"
            />
            <p className="text-secondary">Portal do Cliente</p>
          </div>
          <Row className="justify-content-center">{children}</Row>
        </Container>
      </main>
      <footer className="text-center">
        <Container className="text-secondary">
          VIBE Club &copy; {new Date().getFullYear()}
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default AuthLayout
