import * as yup from 'yup'

export default {
  requiredString: yup.string().required('Campo obrigatório'),
  validPassword: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'Deve ter pelo menos 8 caracteres')
    .max(200, 'Máximo 200 caractereres')
    .matches(/[A-Z]/g, 'Deve ter pelo menos uma letra maiúscula')
    .matches(/[a-z]/g, 'Deve ter pelo menos uma letra minúscula')
    .matches(/[\d]/g, 'Deve ter pelo menos um número')
    .matches(
      /[^a-zA-Z\d]/g,
      'Deve ter pelo menos um caractere especial (exemplos: ! @ # $ % , . ?)',
    ),
}
